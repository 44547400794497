import React from 'react';
import './App.css';
import { SidebarProvider } from 'context/SideBarContext';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from 'pages/dashboard/Dashboard';
import Sms from 'pages/sms';
import ProtectedRoute from 'ProtectedRoute';
import Login from 'pages/auth/Login';
import { AuthProvider } from 'context/AuthContext';
import Profile from 'pages/profile';
import Fines from 'pages/Fines';
import FineDetails from 'pages/Fines/SingleFine';
import Violations from 'pages/Violations';
import UpdateUser from 'pages/Users/SingleUser';
import Users from 'pages/Users';
import MainLayout from 'layouts/MainLayout';
import FineViewAuth from 'pages/auth/FineViewAuth';

function App() {
  return (
    <AuthProvider>
      <SidebarProvider>
        <Routes>
          {/* Protected Routes */}
          <Route element={<MainLayout />}>
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/fines/:page" element={<Fines />} />
              <Route
                path="/fines"
                element={<Navigate to="/fines/1" replace />}
              />
              <Route path="/sms" element={<Sms />} />
              <Route path="/violations/:page" element={<Violations />} />
              <Route
                path="/violations"
                element={<Navigate to="/violations/1" replace />}
              />
              <Route path="/users/:page" element={<Users />} />
              <Route
                path="/users"
                element={<Navigate to="/users/1" replace />}
              />
              <Route path="/profile" element={<Profile />} />
              <Route path="/users/update/:id" element={<UpdateUser />} />
            </Route>
            <Route path="/fines/single/:id" element={<FineDetails />} />
          </Route>

          {/* Public Routes */}
          <Route path="/login" element={<FineViewAuth />} />
          <Route path="/auth/login" element={<Login />} />
        </Routes>
      </SidebarProvider>
    </AuthProvider>
  );
}

export default App;
