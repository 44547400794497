import { CircleMarker, MapContainer, Popup, TileLayer } from 'react-leaflet';
import * as React from 'react';
import 'leaflet/dist/leaflet.css';
import { LatLngExpression } from 'leaflet';

type Coordinates = {
  lat: number;
  lng: number;
  count: number;
};

type Props = {
  coordinates: Coordinates[];
};

const MapComponent = ({ coordinates }: Props) => {
  const getMarkerSize = (count: number) => {
    return Math.min(Math.max(count * 2, 5), 30); // Min 5px, max 30px
  };

  // Define center as LatLngExpression
  const defaultCenter: LatLngExpression = [-1.2921, 36.8219];

  return (
    <div className="h-96 w-full">
      <MapContainer
        style={{ height: '100%', width: '100%' }}
        center={defaultCenter}
        zoom={13}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {coordinates.map((coord, index) => {
          const position: LatLngExpression = [coord.lat, coord.lng];

          return (
            <CircleMarker
              key={index}
              center={position}
              pathOptions={{
                color: 'blue',
                fillColor: 'blue',
                fillOpacity: 0.6,
              }}
              radius={getMarkerSize(coord.count)}
            >
              <Popup>
                Location: {coord.lat.toFixed(4)}, {coord.lng.toFixed(4)} <br />
                Count: {coord.count}
              </Popup>
            </CircleMarker>
          );
        })}
      </MapContainer>
    </div>
  );
};

export default MapComponent;
