import React from 'react';
import { useKeycloak } from '@react-keycloak/web';

import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  const { keycloak } = useKeycloak();
  if (!keycloak?.authenticated) {
    return <Navigate to="/auth/login" />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
