import { AxiosProgressEvent } from 'axios';
import { axiosInstance2 } from 'hooks/auth/AxiosInstance';

export const uploadService = {
  uploadFiles: async (
    files: File[],
    endpoint: string,
    onProgress?: (progress: number) => void,
  ) => {
    const formData = new FormData();
    files.forEach((file) => formData.append('file', file));

    const response = await axiosInstance2.post(endpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const percentCompleted = progressEvent.total
          ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
          : 0;
        onProgress?.(percentCompleted);
      },
    });

    return response.data;
  },
};
