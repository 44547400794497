import React, { ReactNode } from 'react';

type CardProps = {
  children: ReactNode;
};
const Card = ({ children }: CardProps) => {
  return (
    <div className="bg-white flex flex-col items-center shadow-md rounded-lg p-5">
      {children}
    </div>
  );
};

export default Card;
