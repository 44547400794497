import Card from 'components/dashboard/Card';
import React, { useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import MapComponent from './Map';
import { Activity, Camera, AlertTriangle, Ban } from 'lucide-react';
import useGetFines from 'hooks/fines/useGetFines';
import useGetOffences from 'hooks/offences/useGetOffences';
import DashboardSkeleton from 'components/common/skeletons/DashboardSkeleton';
interface AggregatedDataItem {
  date: string;
  count: number;
  totalAmount: number;
}
interface AggregatedHourlyItem {
  count: number;
  hour: number;
  totalAmount: number;
}

interface DailyAggregation {
  [key: string]: AggregatedDataItem;
}
interface HourlyAggregation {
  [key: string]: AggregatedHourlyItem;
}
const Dashboard = () => {
  const { data: fines, isLoading: finesLoading } = useGetFines({
    options: { page: '1', pageSize: '10' },
  });
  const { data: offences, isLoading: offencesLoading } = useGetOffences();
  const cardItems = [
    {
      title: 'Total Fines',
      figure: fines?.finesList.totalElements,
      icon: Ban,
    },
    {
      title: 'Total Violations',
      figure: offences?.length,
      icon: AlertTriangle,
    },
    { title: 'Active Cameras', figure: '2', icon: Camera },
    {
      title: 'Highest fine',
      figure: `ksh ${fines?.finesList.content.reduce((max, item) => Math.max(max, item.amount), 0).toLocaleString()}`,
      icon: Activity,
    },
  ];
  const aggregatedData = useMemo(() => {
    const groupedByDay = fines?.finesList.content.reduce<DailyAggregation>(
      (acc, curr) => {
        // Get date without time
        const date = new Date(curr.dateCaptured).toISOString().split('T')[0];

        if (!acc[date]) {
          acc[date] = {
            date,
            count: 0,
            totalAmount: 0,
          };
        }

        acc[date].count += 1;
        acc[date].totalAmount += curr.amount;

        return acc;
      },
      {},
    );

    // Convert to array and sort by date
    return Object.values(groupedByDay ?? {}).sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );
  }, [fines]);
  const aggregatedDataHourly = useMemo(() => {
    const groupedByHour = fines?.finesList.content.reduce<HourlyAggregation>(
      (acc, curr) => {
        // Extract the hour from the dateCaptured
        const date = new Date(curr.dateCaptured);
        const hour = date.getHours(); // Only group by hour, ignore the day

        // Use the hour as the key (e.g., '10:00' for 10 AM)
        const hourKey = `${String(hour).padStart(2, '0')}:00`;

        if (!acc[hourKey]) {
          acc[hourKey] = {
            hour,
            count: 0,
            totalAmount: 0,
          };
        }

        acc[hourKey].count += 1;
        acc[hourKey].totalAmount += curr.amount;

        return acc;
      },
      {},
    );

    // Convert the aggregated data to an array and sort by hour
    return Object.values(groupedByHour ?? {}).sort((a, b) => a.hour - b.hour);
  }, [fines]);

  const smsData = useMemo(() => {
    return [
      {
        name: 'sms',
        failed: fines?.finesList.content.filter(
          (item) => item.smsStatus === 'F',
        ).length,
        sent: fines?.finesList.content.filter((item) => item.smsStatus === 'S')
          .length,
        pending: fines?.finesList.content.filter(
          (item) => item.smsStatus === 'P',
        ).length,
      },
    ];
  }, [fines]); // Only recompute if fines changes
  const aggregatedDataByCoordinates = useMemo(() => {
    const groupedByCoordinates = fines?.finesList.content.reduce<{
      [key: string]: { lat: number; lng: number; count: number };
    }>((acc, curr) => {
      // Skip if gps is empty or not in the correct format
      if (!curr.gps || !curr.gps.includes(',')) return acc;

      // Extract the gps string and split it into lat/lng
      const gps = curr.gps.split(',');
      const lat = parseFloat(gps[0]);
      const lng = parseFloat(gps[1]);

      // Skip if lat/lng are not valid numbers
      if (isNaN(lat) || isNaN(lng)) return acc;

      // Create a key for the coordinates
      const coordinateKey = `${lat},${lng}`;

      if (!acc[coordinateKey]) {
        acc[coordinateKey] = {
          lat,
          lng,
          count: 0,
        };
      }

      acc[coordinateKey].count += 1; // Aggregate count for the same coordinate

      return acc;
    }, {});

    // Convert the aggregated data to an array
    return Object.values(groupedByCoordinates ?? []);
  }, [fines]);

  if (finesLoading || offencesLoading) return <DashboardSkeleton />;

  return (
    <div className="lg:p-6 p-2">
      {/* <div className="mb-6 flex  justify-end items-end lg:w-1/4">
          <select className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md">
            <option value="weekly">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="weekly">Biweekly</option>
            <option value="weekly">Monthly</option>
            <option value="weekly">3 Months</option>
            <option value="weekly">6 months</option>
            <option value="weekly">1 year</option>
          </select>
        </div> */}
      <div className=" grid grid-cols-1 sm:grids-cols-2 lg:grid-cols-4 gap-6 ">
        {cardItems.map((item) => (
          <div key={item.title}>
            <Card>
              <div className="flex space-x-12 items-center">
                <item.icon size={30} />
                <div>
                  <h3 className=" text-black text-lg font-medium">
                    {item.title}
                  </h3>
                  <p className="text-2xl font-bold text-chartBlue mt-3">
                    {item.figure}
                  </p>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
      <div className=" grid grid-cols-1 mt-8 sm:grids-cols-2 lg:grid-cols-2 gap-6 ">
        <Card>
          <h3 className=" text-gray-600 mb-5 text-lg font-medium">
            Fines collection overview
          </h3>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={aggregatedData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis angle={-45} textAnchor="end" height={70} dataKey="date" />
              <YAxis
                label={{
                  value: 'Number of Fines',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 10,
                }}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="count"
                stroke="#3282b8"
                strokeWidth={2}
                dot={{ fill: '#3282b8', strokeWidth: 2 }}
                name="Number of Fines"
              />
            </LineChart>
          </ResponsiveContainer>
        </Card>
        <Card>
          <h3 className=" text-gray-600 mb-5 text-lg font-medium">
            Fines occurences by time of the day
          </h3>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={aggregatedDataHourly}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis angle={-45} textAnchor="end" height={70} dataKey="hour" />
              <YAxis
                label={{
                  value: 'Number of Fines',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 10,
                }}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="count"
                stroke="#3282b8"
                strokeWidth={2}
                dot={{ fill: '#3282b8', strokeWidth: 2 }}
                name="Number of Fines"
              />
            </LineChart>
          </ResponsiveContainer>
        </Card>
        {/* <Card>
            <h3 className=" text-gray-600 mb-5 text-lg font-medium">
              Violation occurences overview
            </h3>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  label={{
                    value: 'Number of Fines',
                    angle: -90,
                    position: 'insideLeft',
                    offset: 10,
                  }}
                />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="amount"
                  stroke="#3282b8"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </Card> */}
        <Card>
          <h3 className=" text-gray-600 mb-5 text-lg font-medium">
            SMS delivery overview
          </h3>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={smsData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="sent" fill="#10B981" barSize={40} />
              <Bar dataKey="pending" fill="#F59E0B" barSize={40} />
              <Bar dataKey="failed" fill="#F87171" barSize={40} />
            </BarChart>
          </ResponsiveContainer>
        </Card>

        <Card>
          <h3 className=" text-gray-600 mb-5 text-lg font-medium">
            Violation location overview
          </h3>
          <MapComponent coordinates={aggregatedDataByCoordinates} />
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
