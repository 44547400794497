import React, { ElementType } from 'react';

type Props = {
  onClick?: () => void;
  label: string;
  Icon?: ElementType;
  color: string;
  backGroundColor: string;
  hoverColor: string;
  hoverBackgroundColor: string;
  isLoading?: boolean;
};
const Button = ({
  onClick,
  label,
  Icon,
  color,
  backGroundColor,
  hoverColor,
  hoverBackgroundColor,
  isLoading,
}: Props) => {
  return (
    <button
      onClick={onClick}
      className={`px-4 py-1  ${Icon && 'flex items-center'} border border-gray-300 rounded-md text-${color} bg-${backGroundColor} hover:bg-${hoverBackgroundColor} hover:text-${hoverColor} focus:outline-none focus:ring-2 focus:ring-offset-2 `}
    >
      {isLoading ? (
        <>
          <svg
            className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
          Loading...
        </>
      ) : (
        <>
          {Icon && <Icon size={16} className="mr-2" />}
          {label}
        </>
      )}
    </button>
  );
};

export default Button;
