import { Offence } from 'utils/types';
import { AxiosResponse } from 'axios';
import { axiosInstance } from 'hooks/auth/AxiosInstance';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
const fetchData = async (): Promise<Offence[]> => {
  try {
    const response: AxiosResponse<Offence[]> =
      await axiosInstance.get('offence');
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error('An uknown error occured');
    }
  }
};

const useGetOffences = (): UseQueryResult<Offence[], Error> => {
  return useQuery<Offence[], Error>({
    queryKey: ['offences'],
    queryFn: fetchData,
    refetchInterval: 10000, // Fetches every 10 seconds
    refetchOnWindowFocus: false,
  });
};

export default useGetOffences;
