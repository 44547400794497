import { FineResponse } from 'utils/types';
import { AxiosResponse } from 'axios';
import { axiosInstance } from 'hooks/auth/AxiosInstance';

import { UseQueryResult, useQuery } from '@tanstack/react-query';
type PageType = {
  pageSize: string;
  page: string;
};
type Options = {
  options: PageType;
};
const fetchData = async ({
  options: { page = '1', pageSize = '100' },
}: Options): Promise<FineResponse> => {
  try {
    const response: AxiosResponse<FineResponse> = await axiosInstance.get(
      `fine/page/${page}/${pageSize}`,
    );
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

const useGetFines = ({
  options,
}: Options): UseQueryResult<FineResponse, Error> => {
  const { page = '1', pageSize = '100' } = options;
  return useQuery<FineResponse, Error>({
    queryKey: ['fines', page, pageSize],
    queryFn: () => fetchData({ options }),
    refetchInterval: 10000, // Fetches every 10 seconds
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
};

export default useGetFines;
