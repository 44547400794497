import React, { useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Navigate, useLocation } from 'react-router-dom';
import { LogIn } from 'lucide-react';

const Login: React.FC = () => {
  const location = useLocation();
  const currentLocationState = location.state || {
    from: { pathname: '/' },
  };

  const { keycloak } = useKeycloak();

  const login = useCallback(() => {
    keycloak?.login();
  }, [keycloak]);

  if (keycloak?.authenticated)
    return <Navigate to={currentLocationState?.from as string} />;

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-100 to-gray-200 p-4">
      <div className="w-full max-w-md bg-white shadow-2xl rounded-2xl overflow-hidden">
        <div className="p-8 text-center">
          <div className="mb-8">
            <div className="w-24 h-24 mx-auto mb-6 bg-indigo-100 rounded-full flex items-center justify-center">
              <LogIn className="w-12 h-12 text-black" />
            </div>
            <h1 className="text-3xl font-bold text-gray-800 mb-4">
              Access Your Dashboard
            </h1>
            <p className="text-gray-600 text-sm">
              Securely authenticate to continue to your personalized workspace
            </p>
          </div>

          <button
            onClick={login}
            className="w-full py-3 px-4 
              bg-gradient-to-r from-indigo-600 to-purple-600 text-white 
              font-semibold rounded-lg 
              hover:from-indigo-700 hover:to-purple-700 
              focus:outline-none focus:ring-2 focus:ring-offset-2 
              focus:ring-indigo-500 
              transition-all duration-300 
              ease-in-out transform hover:scale-105 active:scale-95 
              flex items-center justify-center gap-3"
          >
            <LogIn className="w-5 h-5" />
            Click to Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
