import SubmitButton from 'components/shared/Button/SubmitButton';
import { Save, User } from 'lucide-react';
import React from 'react';

const AddUser = () => {
  return (
    <div className="px-6 py-12">
      <div className="flex items-center space-x-4 mb-8 border-b">
        <User size={30} />
        <div className="flex flex-col">
          <h1 className="text-xl font-semibold text-gray-800">Add User</h1>
          <p className="mb-3 text-sm text-gray-500">
            Create a user and assign them roles
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            First Name
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Last Name
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Email
          </label>
          <input
            type="email"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Role
          </label>
          <select className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500">
            <option selected>select a role..</option>
            <option value="ADMIN">Admin</option>
            <option value="ANALYST">Analyst</option>
          </select>
        </div>
      </div>

      {/* Actions */}
      <div className="flex justify-end mt-8">
        <SubmitButton
          onClick={() => console.log('saving')}
          isLoading={false}
          Icon={Save}
          label="Save user"
        />
      </div>
    </div>
  );
};

export default AddUser;
