import MainLayout from 'layouts/MainLayout';
import React from 'react';

const Profile = () => {
  return (
    <MainLayout>
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-lg font-semibold mb-4">
          Heey, Welcome to Your Profile page
        </h2>
        <p className="text-gray-600">Something coming soon...</p>
      </div>
    </MainLayout>
  );
};

export default Profile;
