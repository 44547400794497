import axios, { AxiosInstance } from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

const axiosInstance: AxiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
const axiosInstance2: AxiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});
// Add an interceptor to attach the Authorization token if available
axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('noti_jwt');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error); // No need to create a new Error object
  },
);

export { axiosInstance, axiosInstance2 };
