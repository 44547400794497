/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import Button from 'components/shared/Button/Button';
import SubmitButton from 'components/shared/Button/SubmitButton';
import SingleViewLayout from 'layouts/SingleViewLayout';
import { Save, Trash2 } from 'lucide-react';

const UpdateUser = () => {
  // State to manage form data
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  });

  // State to manage loading, error, and confirmation dialog
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  // Handle input changes
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      // TODO: Replace with actual API call to update user
      console.log('Updating user:', formData);
      // Example API call:
      // const response = await updateUserApi(formData);
      alert('User updated successfully');
    } catch (err: any) {
      setError('error');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle user deactivation
  const handleDeactivate = async () => {
    setIsLoading(true);
    try {
      // TODO: Replace with actual API call to deactivate user
      console.log('Deactivating user');
      // Example API call:
      // const response = await deactivateUserApi(userId);
      alert('User deactivated successfully');
      setIsConfirmDialogOpen(false);
    } catch (err: any) {
      setError('error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SingleViewLayout title="Update the user">
      {/* Confirm Dialog */}
      {isConfirmDialogOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full">
            <h2 className="text-lg font-semibold mb-4 text-gray-900">
              Are you absolutely sure?
            </h2>
            <p className="text-sm text-gray-600 mb-6">
              This action will deactivate the user account. This cannot be
              undone.
            </p>
            <div className="flex justify-end space-x-2">
              <Button
                label="Cancel"
                color="gray-700"
                backGroundColor="white"
                hoverColor=""
                hoverBackgroundColor="gray-100"
                onClick={() => setIsConfirmDialogOpen(false)}
              />
              <Button
                label="Deactivate"
                color="white"
                backGroundColor="red-600"
                hoverColor=""
                hoverBackgroundColor="red-700"
                onClick={handleDeactivate}
              />
            </div>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="px-6 py-12">
        {error && (
          <div className="mb-4 p-4 bg-red-100 text-red-700 rounded">
            {error}
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              First Name
            </label>
            <input
              id="firstName"
              name="firstName"
              type="text"
              value={formData.firstName}
              onChange={handleInputChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label
              htmlFor="lastName"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Last Name
            </label>
            <input
              id="lastName"
              name="lastName"
              type="text"
              value={formData.lastName}
              onChange={handleInputChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label
              htmlFor="role"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Role
            </label>
            <select
              id="role"
              name="role"
              value={formData.role}
              onChange={handleInputChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Select a role...</option>
              <option value="ADMIN">Admin</option>
              <option value="ANALYST">Analyst</option>
            </select>
          </div>
        </div>

        {/* Actions */}
        <div className="flex flex-col sm:flex-row sm:space-x-4 sm:space-y-0 space-y-4 mt-8">
          <Button
            label="Cancel"
            color="gray-700"
            backGroundColor="white"
            hoverBackgroundColor="black"
            hoverColor="white"
          />

          <SubmitButton
            isLoading={isLoading}
            Icon={Save}
            label="Update user"
            onClick={() => console.log('saving user')}
          />

          <Button
            label="Deactivate User"
            color="white"
            backGroundColor="red-500"
            hoverBackgroundColor="red-700"
            hoverColor=""
            Icon={Trash2}
            onClick={() => setIsConfirmDialogOpen(true)}
          />
        </div>
      </form>
    </SingleViewLayout>
  );
};

export default UpdateUser;
