/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import NoDataHandling from 'components/shared/NoData/NoDataHandling';
import { Fine } from 'utils/types';
import useGetFineImage from 'hooks/fines/useGetFineImages';
import Spinner from 'components/common/spinner';
import moment from 'moment';

interface InfoItemProps {
  label: string;
  value: string | number;
}
type Props = {
  fine?: Fine;
};

const InfoItem: React.FC<InfoItemProps> = ({ label, value }) => (
  <div className="space-y-1">
    <p className="text-sm text-gray-500">{label}</p>
    <p className="font-medium">{value}</p>
  </div>
);

const FineView = ({ fine }: Props) => {
  const { data: image, isLoading } = useGetFineImage({
    id: fine?.violationId ?? '',
  });
  if (isLoading) return <Spinner />;
  if (!fine)
    return (
      <NoDataHandling content="No fine details found for the specified ID." />
    );
  return (
    <div className="p-6 space-y-6">
      {/* Main Details Card */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200">
        <div className="px-6  py-4 bg-ntsaGreen border-b border-gray-200 flex items-center justify-between">
          <div>
            <h2 className="text-xl text-white font-semibold">
              Ticket #{fine.id.toString()?.slice(-6).toUpperCase()}
            </h2>
            <p className="text-sm font-bold text-white">
              Issued on{' '}
              {moment(fine.dateCaptured).format('Do MMMM YYYY h:mm a')}
            </p>
          </div>
        </div>
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <InfoItem
              label="Vehicle Registration"
              value={fine.vehicleRegistrationNumber}
            />
            <InfoItem label="Location" value={fine.location} />
            <InfoItem label="Speed Limit" value={fine.speedLimit} />
            <InfoItem label="Vehicle Speed" value={fine.vehicleSpeed} />
          </div>
        </div>
      </div>
      <div className="w-full h-[190px] lg:h-[650px] overflow-hidden relative">
        <img
          src={`data:image/png;base64, ${image?.data.content}`}
          alt="fineimage"
          className="h-fit w-fit"
        />
      </div>
    </div>
  );
};

export default FineView;
