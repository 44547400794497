import React from 'react';
import Skeleton from './Skeleton';

const TableSkeleton: React.FC = () => {
  return (
    <div className="overflow-x-auto bg-white shadow-md rounded-lg p-6">
      <table className="table-auto w-full border-collapse">
        <thead>
          <tr>
            {Array.from({ length: 8 }).map((_, i) => (
              <th key={i} className="px-4 py-2">
                <Skeleton className="h-4 w-20" />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 20 }).map((_, rowIndex) => (
            <tr key={rowIndex} className="border-b">
              {Array.from({ length: 8 }).map((i, colIndex) => (
                <td key={colIndex} className="px-4 py-2">
                  <Skeleton className="h-4 w-full" />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeleton;
