import {
  UseMutationResult,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';
import { axiosInstance } from 'hooks/auth/AxiosInstance';
import { FineAuth } from 'utils/types';

const fineAuth = async (data: FineAuth) => {
  const response = await axiosInstance.post(
    `fine/case-details/${data.ticketNo}/${data.password}`,
    data,
  );
  return response.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useFineAuth = (): UseMutationResult<any, Error, FineAuth> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: fineAuth,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fines'] });
    },
    onError: (error: Error) => {
      console.error('Error filtering fines:', error.message);
    },
  });
};

export default useFineAuth;
