import React, { useState } from 'react';
import { Filter, FileText, Sheet, Plus } from 'lucide-react';
import Table, { Column } from 'components/common/table';
import DialogLayout from 'layouts/DialogLayout';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import { useAuth } from 'context/AuthContext';
import { Fine } from 'utils/types';
import Button from 'components/shared/Button/Button';
// import SubmitButton from 'components/shared/Button/SubmitButton';
import { useSearchParams } from 'react-router-dom';
import { FileUpload } from './FineUpload';
import useFilterFines from 'hooks/fines/useFilterFines';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import SubmitButton from 'components/shared/Button/SubmitButton';
interface FinesTableProps {
  data: Fine[];
  onRowClick?: (fine: Fine) => void;
  pageSize?: number;
  currentPage?: number;
  totalPages: number;
  goToPage: (pageNumber: number) => void;
}

interface FinesFilters {
  location: string;
  vehicleInfo: string;
  dateRange: {
    start: string;
    end: string;
  };
  amountRange: {
    min: string;
    max: string;
  };
  paymentStatus: string;
}
interface Response {
  finesListEmpty: string;
  finesList: Fine[];
}

const FinesTable: React.FC<FinesTableProps> = ({
  data: initialData,
  onRowClick,
  pageSize,
  currentPage,
  totalPages,
  goToPage,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [isFileOpen, setIsFileOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  // const [filteredData, setFilteredData] = useState(initialData);
  const [filters, setFilters] = useState<FinesFilters>({
    location: searchParams.get('location') || '',
    vehicleInfo: searchParams.get('vehicleInfo') || '',
    dateRange: {
      start: searchParams.get('startDate') || '',
      end: searchParams.get('endDate') || '',
    },
    amountRange: {
      min: searchParams.get('minAmount') || '',
      max: searchParams.get('maxAmount') || '',
    },
    paymentStatus: searchParams.get('paymentStatus') || '',
  });
  const [errors, setErrors] = useState({ start: false, end: false });

  const validateDates = () => {
    const newErrors = {
      start: !filters.dateRange.start,
      end: !filters.dateRange.end,
    };
    setErrors(newErrors);

    return !newErrors.start && !newErrors.end;
  };
  const { mutateAsync: filterFinesCSV, isPending: isPendingCSV } =
    useFilterFines();
  const { mutateAsync: filterFinesPDF, isPending: isPendingPDF } =
    useFilterFines();
  const { auth } = useAuth();

  const columns: Column[] = [
    {
      header: 'Date',
      accessorKey: 'dateCaptured',
      cell: (value: string) => moment(value).format('Do MMMM YYYY h:mm a'),
    },
    {
      header: 'Vehicle Registration',
      accessorKey: 'vehicleRegistrationNumber',
      editable: true,
    },
    {
      header: 'Location',
      accessorKey: 'location',
    },
    {
      header: 'Type of Vehicle',
      accessorKey: 'vehicleType',
    },

    {
      header: 'Status',
      accessorKey: 'status',
      cell: (value: string) => (
        <span
          className={` text-white px-3 py-1 rounded-3xl justify-center flex items-center ${value === 'PAID' ? ' bg-green-500' : ' bg-red-500'}`}
        >
          {value === 'PAID' ? 'Paid' : 'Not paid'}
        </span>
      ),
    },
    {
      header: 'Speed Limit',
      accessorKey: 'speedLimit',
    },
    {
      header: 'Vehicle Speed',
      accessorKey: 'vehicleSpeed',
    },
    {
      header: 'Fine Amount',
      accessorKey: 'amount',
    },
  ];
  const exportPDF = (data: Fine[]) => {
    const doc = new jsPDF();
    doc.text('Fines Report', 20, 10);
    autoTable(doc, {
      head: [
        [
          'Date',
          'Location',
          'Vehicle Registration',
          'Time',
          'Status',
          'Speed Limit',
          'Vehicle Speed',
          'Fine Amount',
        ],
      ],
      body: data.map((item) => [
        moment(item.dateCreated).format('YYYY-MM-DD'),
        item.location,
        item.vehicleRegistrationNumber,
        moment(item.dateCreated).format('hh:mm A'),
        item.status,
        item.speedLimit,
        item.vehicleSpeed,
        item.amount,
      ]),
    });
    doc.save('fines-report.pdf');
  };
  const downloadPDF = async () => {
    if (validateDates()) {
      const post = {
        fromDate: moment(filters.dateRange.start).format('YYYY-MM-DD'),
        toDate: moment(filters.dateRange.end).format('YYYY-MM-DD'),
        location: filters.location,
        vehicleRegistrationNumber: filters.vehicleInfo,
        paymentStatus: filters.paymentStatus,
      };

      await filterFinesPDF(post)
        .then((data: Response) => {
          if (data.finesListEmpty !== 'true') {
            exportPDF(data.finesList);
          }
        })
        .catch((error) => {
          console.error('Error fetching fines data:', error);
        });
    }
  };
  const downloadCSV = async () => {
    if (validateDates()) {
      const post = {
        fromDate: moment(filters.dateRange.start).format('YYYY-MM-DD'),
        toDate: moment(filters.dateRange.end).format('YYYY-MM-DD'),
        location: filters.location,
        vehicleRegistrationNumber: filters.vehicleInfo,
        paymentStatus: filters.paymentStatus,
      };

      await filterFinesCSV(post)
        .then((data: Response) => {
          if (data.finesListEmpty !== 'true') {
            const csvData = Papa.unparse(
              data.finesList.filter((item) => item.smsStatus === 'S'),
            ); // Converts JSON array to CSV format

            // Create a Blob object for the CSV
            const blob = new Blob([csvData], {
              type: 'text/csv;charset=utf-8;',
            });

            // Trigger download
            saveAs(blob, 'fines.csv');
          }
        })
        .catch((error) => {
          console.error('Error fetching fines data:', error);
        });
    }
  };
  const handleRowUpdate = (updatedRow: Fine) => {
    console.log(updatedRow);
  };
  // const applyFilters = () => {
  //   let result = [...initialData];

  //   if (filters.location) {
  //     result = result.filter((item) =>
  //       item.location.toLowerCase().includes(filters.location.toLowerCase()),
  //     );
  //   }

  //   if (filters.vehicleInfo) {
  //     result = result.filter((item) =>
  //       item.vehicleRegistrationNumber
  //         .toLowerCase()
  //         .includes(filters.vehicleInfo.toLowerCase()),
  //     );
  //   }
  //   if (filters.paymentStatus) {
  //     result = result.filter((item) =>
  //       item.status.toLowerCase().includes(filters.paymentStatus.toLowerCase()),
  //     );
  //   }

  //   if (filters.dateRange.start || filters.dateRange.end) {
  //     result = result.filter((item) => {
  //       const itemDate = new Date(item.dateCaptured);
  //       const startDate = filters.dateRange.start
  //         ? new Date(filters.dateRange.start)
  //         : null;
  //       const endDate = filters.dateRange.end
  //         ? new Date(filters.dateRange.end)
  //         : null;

  //       // Set the time to ignore the time component
  //       itemDate.setHours(0, 0, 0, 0);
  //       if (startDate) startDate.setHours(0, 0, 0, 0);
  //       if (endDate) endDate.setHours(23, 59, 59, 999);

  //       // Filter based on the available dates
  //       if (startDate && endDate) {
  //         return itemDate >= startDate && itemDate <= endDate;
  //       } else if (startDate) {
  //         return itemDate >= startDate;
  //       } else if (endDate) {
  //         return itemDate <= endDate;
  //       }
  //       return true; // If no dates are provided (shouldn't reach here based on the initial condition)
  //     });
  //   }

  //   if (filters.amountRange.min || filters.amountRange.max) {
  //     result = result.filter((item) => {
  //       const min = filters.amountRange.min
  //         ? parseFloat(filters.amountRange.min)
  //         : -Infinity;
  //       const max = filters.amountRange.max
  //         ? parseFloat(filters.amountRange.max)
  //         : Infinity;
  //       return item.amount >= min && item.amount <= max;
  //     });
  //   }
  //   const newSearchParams = new URLSearchParams();

  //   if (filters.location) newSearchParams.set('location', filters.location);
  //   if (filters.vehicleInfo)
  //     newSearchParams.set('vehicleInfo', filters.vehicleInfo);
  //   if (filters.dateRange.start)
  //     newSearchParams.set('startDate', filters.dateRange.start);
  //   if (filters.dateRange.end)
  //     newSearchParams.set('endDate', filters.dateRange.end);
  //   if (filters.paymentStatus)
  //     newSearchParams.set('paymentStatus', filters.paymentStatus);

  //   // Reset to first page
  //   newSearchParams.set('page', '1');

  //   setSearchParams(newSearchParams);
  //   setFilteredData(result);
  //   setFilteredData(result);
  //   setIsFilterOpen(false);
  // };
  const resetFilters = () => {
    setFilters({
      location: '',
      vehicleInfo: '',
      dateRange: { start: '', end: '' },
      amountRange: { min: '', max: '' },
      paymentStatus: '',
    });
    setIsFilterOpen(false);

    // Clear the search params from the URL
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('page', '1'); // Optionally, reset to the first page
    setSearchParams(newSearchParams);
  };

  const onClose = () => setIsFilterOpen(false);
  return (
    <div className="space-y-4">
      <div className="bg-white rounded-lg shadow p-4">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold text-gray-800">Fines List</h2>
          {auth === 'admin' && (
            <div className="flex space-x-4">
              <Button
                label="Upload "
                color="white"
                backGroundColor="black"
                hoverBackgroundColor="gray-200"
                hoverColor="black"
                Icon={Plus}
                onClick={() => setIsFileOpen(true)}
              />
              <Button
                label="Reports"
                color="black"
                backGroundColor="gray-100"
                hoverBackgroundColor="gray-200"
                hoverColor="black"
                Icon={FileText}
                onClick={() => setIsReportOpen(true)}
              />
              <Button
                label="Filters"
                color="black"
                backGroundColor="gray-100"
                hoverBackgroundColor="gray-200"
                hoverColor="black"
                Icon={Filter}
                onClick={() => setIsFilterOpen(!isFilterOpen)}
              />
            </div>
          )}
        </div>
        {isFileOpen && (
          <DialogLayout
            width="100%"
            isOpen={isFileOpen}
            onClose={() => setIsFileOpen(false)}
          >
            <div className="py-12 px-12">
              <FileUpload />
            </div>
          </DialogLayout>
        )}
        {isReportOpen && (
          <DialogLayout
            isOpen={isReportOpen}
            onClose={() => setIsReportOpen(false)}
          >
            <div className="p-8 w-full">
              <div className="mt-4">
                <label className="block text-lg font-medium text-gray-700 mb-1">
                  From
                </label>
                <input
                  type="date"
                  value={filters.dateRange.start}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      dateRange: {
                        ...filters.dateRange,
                        start: e.target.value,
                      },
                    })
                  }
                  className={`w-full px-3 py-3 text-lg border rounded-md ${
                    errors.start ? 'border-red-500' : 'border-gray-300'
                  }`}
                />
                {errors.start && (
                  <p className="text-red-500 text-sm mt-1">
                    Start date is required
                  </p>
                )}
              </div>
              <div className="mt-4">
                <label className="block text-lg mt-4 font-medium text-gray-700 mb-1">
                  To
                </label>
                <input
                  type="date"
                  value={filters.dateRange.end}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      dateRange: {
                        ...filters.dateRange,
                        end: e.target.value,
                      },
                    })
                  }
                  className={`w-full px-3 py-3 text-lg border rounded-md ${
                    errors.start ? 'border-red-500' : 'border-gray-300'
                  }`}
                />
                {errors.end && (
                  <p className="text-red-500 text-sm mt-1">
                    End date is required
                  </p>
                )}
              </div>
              <div className="flex  space-x-4 items-center justify-center  mt-12">
                <Button
                  label="Download as CSV"
                  color="white"
                  backGroundColor="black"
                  hoverBackgroundColor="gray-200"
                  hoverColor="black"
                  Icon={Sheet}
                  onClick={downloadCSV}
                  isLoading={isPendingCSV}
                />
                <Button
                  label="Download as PDF"
                  color="white"
                  backGroundColor="black"
                  hoverBackgroundColor="gray-200"
                  hoverColor="black"
                  Icon={FileText}
                  onClick={downloadPDF}
                  isLoading={isPendingPDF}
                />
              </div>
            </div>
          </DialogLayout>
        )}

        {isFilterOpen && (
          <DialogLayout isOpen={isFilterOpen} onClose={onClose}>
            <div className="mt-4 p-4 bg-gray-50 rounded-lg space-y-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Location Search */}
                <div className="mb-4">
                  <label className="block text-lg font-medium text-gray-700 mb-1">
                    Location
                  </label>
                  <input
                    type="text"
                    value={filters.location}
                    onChange={(e) =>
                      setFilters({ ...filters, location: e.target.value })
                    }
                    placeholder="Search by location..."
                    className="w-full px-3 py-3 text-lg border rounded-md"
                  />
                </div>

                <div>
                  <label className="block text-lg font-medium text-gray-700 mb-1">
                    Vehicle Information
                  </label>
                  <input
                    type="text"
                    value={filters.vehicleInfo}
                    onChange={(e) =>
                      setFilters({ ...filters, vehicleInfo: e.target.value })
                    }
                    placeholder="Search by vehicle info..."
                    className="w-full px-3 py-3 text-lg border rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-lg font-medium text-gray-700 mb-1">
                    Payment Status
                  </label>
                  <select
                    value={filters.paymentStatus}
                    onChange={(e) =>
                      setFilters({ ...filters, paymentStatus: e.target.value })
                    }
                    className="w-full px-3 py-3 text-lg border rounded-md"
                  >
                    <option selected>select an option</option>
                    <option value="PAID">Paid</option>
                    <option value="UNPAID">Not Paid</option>
                  </select>
                </div>

                {/* Date Range */}
                <div>
                  <label className="block text-lg font-medium text-gray-700 mb-1">
                    Date Range
                  </label>
                  <div className="flex space-x-2">
                    <input
                      type="date"
                      value={filters.dateRange.start}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          dateRange: {
                            ...filters.dateRange,
                            start: e.target.value,
                          },
                        })
                      }
                      className="flex-1 px-3 py-3 text-lg border rounded-md"
                    />
                    <input
                      type="date"
                      value={filters.dateRange.end}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          dateRange: {
                            ...filters.dateRange,
                            end: e.target.value,
                          },
                        })
                      }
                      className="flex-1 px-3 py-3 text-lg border rounded-md"
                    />
                  </div>
                </div>

                {/* Amount Range */}
                {/* <div>
                  <label className="block text-lg font-medium text-gray-700 mb-1">
                    Amount Range
                  </label>
                  <div className="flex space-x-2">
                    <input
                      type="number"
                      value={filters.amountRange.min}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          amountRange: {
                            ...filters.amountRange,
                            min: e.target.value,
                          },
                        })
                      }
                      placeholder="Min"
                      className="flex-1 px-3 py-3 text-lg border rounded-md"
                    />
                    <input
                      type="number"
                      value={filters.amountRange.max}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          amountRange: {
                            ...filters.amountRange,
                            max: e.target.value,
                          },
                        })
                      }
                      placeholder="Max"
                      className="flex-1 px-3 py-3 text-lg border rounded-md"
                    />
                  </div>
                </div> */}
              </div>

              {/* Filter Actions */}
              <div className="flex justify-end space-x-2">
                <Button
                  label="Reset"
                  color="gray-600"
                  backGroundColor="white"
                  hoverBackgroundColor="white"
                  hoverColor="gray-800"
                  onClick={resetFilters}
                />
                <SubmitButton label="Apply Filters" Icon={Filter} />
              </div>
            </div>
          </DialogLayout>
        )}
      </div>
      <div className="container mx-auto px-4">
        <Table
          data={initialData}
          columns={columns}
          pageSize={pageSize}
          onRowClick={onRowClick}
          currentPage={currentPage}
          goToPage={goToPage}
          totalPages={totalPages}
          onRowUpdate={handleRowUpdate}
        />
      </div>
    </div>
  );
};
export default FinesTable;
