import MainLayout from 'layouts/MainLayout';
import React from 'react';
type Props = {
  content: string;
};
const NoDataHandling = ({ content }: Props) => {
  return (
    <MainLayout>
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-red-50 border-l-4 border-red-400 p-4 w-96">
          <div className="flex items-center">
            <div className="text-red-400">
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{content}</p>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default NoDataHandling;
