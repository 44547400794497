import { X, ChevronRight, LogOut, User } from 'lucide-react';
import { SidebarItems } from './SideBarItems';
import { useSidebar } from 'context/SideBarContext';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import React, { useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const Sidebar = () => {
  const navigate = useNavigate();
  const { isSidebarOpen, closeSidebar } = useSidebar();
  const { auth } = useAuth();
  const filteredSidebarItems =
    auth === 'admin'
      ? SidebarItems
      : SidebarItems.filter((item) => item.title !== 'Users');
  const { keycloak } = useKeycloak();

  const logout = useCallback(() => {
    // Redirect to /auth/login after logout
    keycloak?.logout({ redirectUri: `${window.location.origin}/auth/login` });
  }, [keycloak]);

  if (!keycloak?.authenticated) {
    // Redirect to /auth/login if already logged out
    window.location.href = '/auth/login';
    return null; // Prevent further rendering
  }
  return (
    <>
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-20 lg:hidden"
          onClick={closeSidebar}
        />
      )}

      <aside
        className={`
          fixed top-0 left-0 z-30
          h-full w-64 bg-white shadow-lg
          transform transition-transform duration-300 ease-in-out
          lg:translate-x-0  lg:h-screen
          flex flex-col overflow-hidden
          ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
        `}
      >
        <div className="flex items-center justify-between p-4 border-b">
          <div className="mt-3 mb-3 flex items-center justify-center">
            <h2 className="text-xl font-bold text-gray-800">
              Enforce Dashboard
            </h2>
          </div>
          <button
            onClick={closeSidebar}
            className="p-1 rounded-md hover:bg-gray-100 lg:hidden"
          >
            <X size={24} />
          </button>
        </div>

        {/* Make the nav section non-scrollable */}
        <nav className="flex-1 p-4 overflow-y-auto">
          <ul className="space-y-4">
            {filteredSidebarItems.map((item, index) => (
              <li key={index}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    `w-full flex items-center space-x-2 p-2 rounded-md ${
                      isActive
                        ? 'bg-gray-200 text-gray-900'
                        : 'hover:bg-gray-100 text-gray-700 hover:text-gray-900'
                    }`
                  }
                >
                  <item.icon size={20} />
                  <span>{item.title}</span>
                  <ChevronRight size={16} className="ml-auto" />
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>

        <div className="border-t p-4 space-y-2">
          <button
            onClick={() => navigate('/profile')}
            className="w-full flex items-center space-x-2 p-2 rounded-md hover:bg-gray-100 text-gray-700 hover:text-gray-900"
          >
            <User size={20} />
            <span>Profile</span>
            <ChevronRight size={16} className="ml-auto" />
          </button>
          <button
            onClick={logout}
            className="w-full flex items-center space-x-2 p-2 rounded-md hover:bg-red-50 text-red-600 hover:text-red-700"
          >
            <LogOut size={20} />
            <span>Logout</span>
          </button>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
