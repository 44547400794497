import React, { useState } from 'react';
// import { Filter, FileText, Sheet } from 'lucide-react';
import Table, { Column } from 'components/common/table';
import DialogLayout from 'layouts/DialogLayout';
// import { CSVLink } from 'react-csv';
// import jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable';
import moment from 'moment';
// import { useAuth } from 'context/AuthContext';
import { Fine } from 'utils/types';
// import useFilterFines from 'hooks/fines/useFilterFines';
import Button from 'components/shared/Button/Button';
// import SubmitButton from 'components/shared/Button/SubmitButton';
import { useSearchParams } from 'react-router-dom';
import { getBackgroundColor } from 'utils/Functions';

interface FinesTableProps {
  data: Fine[];
  onRowClick?: (fine: Fine) => void;
  totalPages: number;
  goToPage: (pageNumber: number) => void;
  pageSize?: number;
  currentPage?: number;
}

interface FinesFilters {
  location: string;
  vehicleInfo: string;
  dateRange: {
    start: string;
    end: string;
  };
  amountRange: {
    min: string;
    max: string;
  };
  paymentStatus: string;
  smsStatus: string;
}
// interface Response {
//   finesListEmpty: string;
//   finesList: Fine[];
// }

const SmsTable: React.FC<FinesTableProps> = ({
  data: initialData,
  onRowClick,
  goToPage,
  totalPages,
  pageSize,
  currentPage,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<FinesFilters>({
    location: searchParams.get('location') || '',
    vehicleInfo: searchParams.get('vehicleInfo') || '',
    dateRange: {
      start: searchParams.get('startDate') || '',
      end: searchParams.get('endDate') || '',
    },
    amountRange: {
      min: searchParams.get('minAmount') || '',
      max: searchParams.get('maxAmount') || '',
    },
    paymentStatus: searchParams.get('paymentStatus') || '',
    smsStatus: searchParams.get('smsStatus') || '',
  });
  // const { mutateAsync: filterFines, isPending } = useFilterFines();
  // const { auth } = useAuth();
  // const csvHeaders = [
  //   {
  //     label: 'Date',
  //     key: 'dateCaptured',
  //     format: (value: string) => moment(value).format('YYYY-MM-DD'),
  //   },
  //   {
  //     label: 'Time',
  //     key: 'dateCaptured',
  //     format: (value: string) => moment(value).format('hh:mm A'),
  //   },
  //   {
  //     label: 'Vehicle Registration',
  //     key: 'vehicleRegistrationNumber',
  //   },
  //   {
  //     label: 'Type of Vehicle',
  //     key: 'vehicleType',
  //   },
  //   {
  //     label: 'Location',
  //     key: 'location',
  //   },
  //   {
  //     label: 'Status',
  //     key: 'status',
  //   },
  //   {
  //     label: 'Speed Limit',
  //     key: 'speedLimit',
  //   },
  //   {
  //     label: 'Vehicle Speed',
  //     key: 'vehicleSpeed',
  //   },
  //   {
  //     label: 'Fine Amount',
  //     key: 'amount',
  //   },
  // ];
  const columns: Column[] = [
    {
      header: 'Date',
      accessorKey: 'dateCaptured',
      cell: (value: string) => moment(value).format('Do MMMM YYYY h:mm a'),
    },
    {
      header: 'Vehicle Registration',
      accessorKey: 'vehicleRegistrationNumber',
    },
    {
      header: 'Location',
      accessorKey: 'location',
    },
    {
      header: 'Type of Vehicle',
      accessorKey: 'vehicleType',
    },
    {
      header: 'Sms status',
      accessorKey: 'smsStatus',
      cell: (value: string) => (
        <span
          className={` ${value === 'P' || value === null ? 'text-black' : 'text-white'} text-black px-3 py-1 rounded-3xl justify-center flex items-center ${getBackgroundColor(value)}`}
        >
          {value === null
            ? 'No status'
            : value === 'S'
              ? 'Sent'
              : value === 'P'
                ? 'Pending'
                : 'Failed'}
        </span>
      ),
    },

    {
      header: 'Speed Limit',
      accessorKey: 'speedLimit',
    },
    {
      header: 'Vehicle Speed',
      accessorKey: 'vehicleSpeed',
    },
    {
      header: 'Fine Amount',
      accessorKey: 'amount',
    },
  ];

  // const applyFilters = async () => {
  //   const post = {
  //     fromDate: moment(filters.dateRange.start).format('YYYY-MM-DD'),
  //     toDate: moment(filters.dateRange.end).format('YYYY-MM-DD'),
  //     location: filters.location,
  //     vehicleRegistrationNumber: filters.vehicleInfo,
  //     paymentStatus: filters.paymentStatus,
  //   };
  //   const filteredPost = Object.entries(post).reduce(
  //     (acc, [key, value]) => {
  //       if (value !== '' && value !== 'Invalid date') {
  //         acc[key] = value;
  //       }
  //       return acc;
  //     },
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     {} as Record<string, any>,
  //   );
  //   await filterFines(filteredPost)
  //     .then((data: Response) => {
  //       if (data.finesListEmpty === 'true') {
  //         setFilteredData([]);
  //       } else {
  //         setFilteredData(data.finesList);
  //         setIsFilterOpen(false);
  //       }
  //     })
  //     .then(() => setIsFilterOpen(false))
  //     .catch((error) => {
  //       console.error('Error fetching fines data:', error);
  //       setFilteredData(initialData);
  //     });
  // };

  const resetFilters = () => {
    setFilters({
      location: '',
      vehicleInfo: '',
      dateRange: { start: '', end: '' },
      amountRange: { min: '', max: '' },
      paymentStatus: '',
      smsStatus: '',
    });
    setIsFilterOpen(false);

    // Clear the search params from the URL
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('page', '1'); // Optionally, reset to the first page
    setSearchParams(newSearchParams);
  };

  const onClose = () => setIsFilterOpen(false);
  return (
    <div className="space-y-4">
      <div className="bg-white rounded-lg shadow p-4">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold text-gray-800">
            Sms status list
          </h2>
          {/* {auth === 'admin' && (
            <div className="flex space-x-4">
              <Button
                label="Reports"
                color="black"
                backGroundColor="gray-100"
                hoverBackgroundColor="gray-200"
                hoverColor="black"
                Icon={FileText}
              />
              <Button
                label="Filters"
                color="black"
                backGroundColor="gray-100"
                hoverBackgroundColor="gray-200"
                hoverColor="black"
                Icon={Filter}
                onClick={() => setIsFilterOpen(!isFilterOpen)}
              />
            </div>
          )} */}
        </div>
        {isReportOpen && (
          <DialogLayout
            isOpen={isReportOpen}
            onClose={() => setIsReportOpen(false)}
          >
            <div className="p-8 w-full">
              {/* <label className="block text-lg font-medium text-gray-700 mb-1">
                From
              </label>
              <input
                type="date"
                value={filters.dateRange.start}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    dateRange: {
                      ...filters.dateRange,
                      start: e.target.value,
                    },
                  })
                }
                className="w-full px-3 py-3 text-lg border rounded-md"
              />
              <label className="block text-lg mt-4 font-medium text-gray-700 mb-1">
                To
              </label>
              <input
                type="date"
                value={filters.dateRange.end}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    dateRange: {
                      ...filters.dateRange,
                      end: e.target.value,
                    },
                  })
                }
                className="w-full px-3 py-3 text-lg border rounded-md"
              /> */}

              {/* <div className="flex flex-col space-y-4 items-center justify-center  mt-12">
                <CSVLink
                  data={filteredData}
                  headers={csvHeaders}
                  filename="fines.csv"
                >
                  <Button
                    label="Download as CSV"
                    color="white"
                    backGroundColor="black"
                    hoverBackgroundColor="gray-200"
                    hoverColor="black"
                    Icon={Sheet}
                  />
                </CSVLink>
                <Button
                  label="Download as PDF"
                  color="white"
                  backGroundColor="black"
                  hoverBackgroundColor="gray-200"
                  hoverColor="black"
                  Icon={FileText}
                  onClick={exportPDF}
                />
              </div> */}
            </div>
          </DialogLayout>
        )}

        {isFilterOpen && (
          <DialogLayout isOpen={isFilterOpen} onClose={onClose}>
            <div className="mt-4 p-4 w-full bg-gray-50 rounded-lg space-y-8">
              <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                {/* Location Search */}
                {/* <div className="mb-4">
                  <label className="block text-lg font-medium text-gray-700 mb-1">
                    Location
                  </label>
                  <input
                    type="text"
                    value={filters.location}
                    onChange={(e) =>
                      setFilters({ ...filters, location: e.target.value })
                    }
                    placeholder="Search by location..."
                    className="w-full px-3 py-3 text-lg border rounded-md"
                  />
                </div> */}

                {/* <div>
                  <label className="block text-lg font-medium text-gray-700 mb-1">
                    Vehicle Information
                  </label>
                  <input
                    type="text"
                    value={filters.vehicleInfo}
                    onChange={(e) =>
                      setFilters({ ...filters, vehicleInfo: e.target.value })
                    }
                    placeholder="Search by vehicle info..."
                    className="w-full px-3 py-3 text-lg border rounded-md"
                  />
                </div> */}
                <div>
                  <label className="block text-lg font-medium text-gray-700 mb-1">
                    Sms Status
                  </label>
                  <select
                    value={filters.smsStatus}
                    onChange={(e) =>
                      setFilters({ ...filters, smsStatus: e.target.value })
                    }
                    className="w-full px-3 py-3 text-lg border rounded-md"
                  >
                    <option selected>select an option</option>
                    <option value="S">Sent</option>
                    <option value="P">Pending</option>
                    <option value="F">Failed</option>
                  </select>
                </div>

                {/* Date Range */}
                <div>
                  <label className="block text-lg font-medium text-gray-700 mb-1">
                    Date Range
                  </label>
                  <div className="flex space-x-2">
                    <input
                      type="date"
                      value={filters.dateRange.start}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          dateRange: {
                            ...filters.dateRange,
                            start: e.target.value,
                          },
                        })
                      }
                      className="flex-1 px-3 py-3 text-lg border rounded-md"
                    />
                    <input
                      type="date"
                      value={filters.dateRange.end}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          dateRange: {
                            ...filters.dateRange,
                            end: e.target.value,
                          },
                        })
                      }
                      className="flex-1 px-3 py-3 text-lg border rounded-md"
                    />
                  </div>
                </div>

                {/* Amount Range */}
                {/* <div>
                  <label className="block text-lg font-medium text-gray-700 mb-1">
                    Amount Range
                  </label>
                  <div className="flex space-x-2">
                    <input
                      type="number"
                      value={filters.amountRange.min}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          amountRange: {
                            ...filters.amountRange,
                            min: e.target.value,
                          },
                        })
                      }
                      placeholder="Min"
                      className="flex-1 px-3 py-3 text-lg border rounded-md"
                    />
                    <input
                      type="number"
                      value={filters.amountRange.max}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          amountRange: {
                            ...filters.amountRange,
                            max: e.target.value,
                          },
                        })
                      }
                      placeholder="Max"
                      className="flex-1 px-3 py-3 text-lg border rounded-md"
                    />
                  </div>
                </div> */}
              </div>

              {/* Filter Actions */}
              <div className="flex justify-end space-x-2">
                <Button
                  label="Reset"
                  color="gray-600"
                  backGroundColor="white"
                  hoverBackgroundColor="white"
                  hoverColor="gray-800"
                  onClick={resetFilters}
                />
                {/* <SubmitButton
                  label="Apply Filters"
                  onClick={applyFilters}
                  Icon={Filter}
                /> */}
              </div>
            </div>
          </DialogLayout>
        )}
      </div>
      <div className="container mx-auto px-4">
        <Table
          data={initialData}
          columns={columns}
          pageSize={pageSize}
          currentPage={currentPage}
          onRowClick={onRowClick}
          goToPage={goToPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};
export default SmsTable;
