/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Car, MessageCircle } from 'lucide-react';
import NoDataHandling from 'components/shared/NoData/NoDataHandling';
import useGetSingleFine from 'hooks/fines/useGetSingleFine';
import SingleViewLayout from 'layouts/SingleViewLayout';
import { useParams } from 'react-router-dom';
import { getBackgroundColor } from 'utils/Functions';
import useGetTrailLogs from 'hooks/fines/useGetFineTrailLogs';
import moment from 'moment';
import useGetFineImage from 'hooks/fines/useGetFineImages';
import FineDetailsSkeleton from 'components/common/skeletons/FineDetailsSkeleton';

interface InfoItemProps {
  label: string;
  value: string | number;
}

const InfoItem: React.FC<InfoItemProps> = ({ label, value }) => (
  <div className="space-y-1">
    <p className="text-sm text-gray-500">{label}</p>
    <p className="font-medium">{value}</p>
  </div>
);

const FineDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: fine, isLoading: fineLoading } = useGetSingleFine(id || '');
  const { data: image, isLoading } = useGetFineImage({
    id: fine?.violationId ?? '',
  });
  const { mutateAsync, isPending } = useGetTrailLogs();
  const [trailLog, setTrailLog] = useState<any>();

  const getTrailLogs = async () => {
    await mutateAsync({ id: id ?? '' })
      .then((data) => {
        if (data.trailLog) {
          setTrailLog(data.trailLog);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getTrailLogs();
  }, []);
  if (fineLoading || isPending || isLoading) return <FineDetailsSkeleton />;

  if (!fine)
    return (
      <NoDataHandling content="No fine details found for the specified ID." />
    );
  return (
    <SingleViewLayout title="Fine Details">
      <div className="lg:p-6 space-y-6">
        {/* Main Details Card */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
          <div className="lg:px-6 px-2 py-4 border-b border-gray-200 flex items-center justify-between">
            <div>
              <h2 className="text-xl font-semibold">
                Ticket #{id?.slice(-6).toUpperCase()}
              </h2>
              <p className="text-sm font-bold text-gray-500">
                Issued on{' '}
                {moment(fine.dateCaptured).format('Do MMMM YYYY h:mm a')}
              </p>
            </div>
            <span
              className={`px-4 py-1 rounded-full text-sm font-medium
                ${
                  fine.status === 'PAID'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800'
                }`}
            >
              {fine.status === 'PAID' ? 'Paid' : 'Not paid'}
            </span>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <InfoItem
                label="Vehicle Registration"
                value={fine.vehicleRegistrationNumber}
              />
              <InfoItem label="Location" value={fine.location} />
              <InfoItem label="Speed Limit" value={fine.speedLimit} />
              <InfoItem label="Vehicle Speed" value={fine.vehicleSpeed} />
              <InfoItem label="Vehicle Type" value={fine.vehicleType} />
              <InfoItem
                label="Amount"
                value={`KES ${fine.amount.toLocaleString()}`}
              />
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
          <div className="px-6 py-4 border-b border-gray-200">
            <div className="flex items-center justify-between">
              <div className="flex space-x-2 items-center">
                <MessageCircle className="h-5 w-5 text-gray-500" />
                <h2 className="text-lg font-semibold">SMS Details</h2>
              </div>
              <span
                className={` ${fine.smsStatus === 'P' || fine.smsStatus === null ? 'text-black' : 'text-white'} text-black px-3 py-1 rounded-3xl justify-center flex items-center ${getBackgroundColor(fine.smsStatus)}`}
              >
                {fine.smsStatus === null
                  ? 'No status'
                  : fine.smsStatus === 'S'
                    ? 'Sent'
                    : fine.smsStatus === 'P'
                      ? 'Pending'
                      : 'Failed'}
              </span>
            </div>
          </div>
          {trailLog !== undefined ? (
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <InfoItem label="Trail Id" value={trailLog?.trailId} />
                <InfoItem
                  label="Trail Time"
                  value={moment(trailLog?.trailTime).format(
                    'Do MMMM YYYY h:mm a',
                  )}
                />
                <InfoItem label="Trail Comment" value={trailLog?.comment} />
              </div>
            </div>
          ) : (
            <div className="p-6">No trail for this case was found</div>
          )}
        </div>
        {/* Owner Details Card */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
          <div className="px-6 py-4 border-b border-gray-200">
            <div className="flex items-center gap-2">
              <Car className="h-5 w-5 text-gray-500" />
              <h2 className="text-lg font-semibold">Owner Details</h2>
            </div>
          </div>
          {fine.ownerDetails === null ? (
            <div className="p-6">Owner details not found</div>
          ) : (
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <InfoItem label="Name" value={fine.ownerDetails.name} />
                <InfoItem
                  label="Phone Number"
                  value={fine.ownerDetails.phoneNumber}
                />
                <InfoItem
                  label="Vehicle Use"
                  value={fine.ownerDetails.vehicleUse}
                />
                <InfoItem label="Vehicle Type" value={fine.vehicleType} />
              </div>
            </div>
          )}
        </div>

        <div className="w-full h-[190px] lg:h-[650px] overflow-hidden relative">
          <img
            src={`data:image/png;base64, ${image?.data.content}`}
            alt="fineimage"
            className="h-fit w-fit"
          />
        </div>
      </div>
    </SingleViewLayout>
  );
};

export default FineDetails;
