import React, { useState } from 'react';
import { Filter, FileText, Sheet } from 'lucide-react';
import Table, { Column } from 'components/common/table';
import DialogLayout from 'layouts/DialogLayout';
import { CSVLink } from 'react-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Offence } from 'utils/types';
import moment from 'moment';
import { useAuth } from 'context/AuthContext';
import Button from 'components/shared/Button/Button';
import SubmitButton from 'components/shared/Button/SubmitButton';

interface ViolationsTableProps {
  data: Offence[];
  onRowClick?: (violation: Offence) => void;
  totalPages: number;
  goToPage: (pageNumber: number) => void;
  pageSize?: number;
  currentPage?: number;
}

interface ViolationFilters {
  violationLabel: string;
  violationTitle: string;
  violationStatus: string;
  dateRange: {
    start: string;
    end: string;
  };
  amountRange: {
    min: string;
    max: string;
  };
}

const ViolationsTable: React.FC<ViolationsTableProps> = ({
  data: initialData,
  onRowClick,
  goToPage,
  totalPages,
  pageSize,
  currentPage,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);

  const [filteredData, setFilteredData] = useState(initialData);
  const [filters, setFilters] = useState<ViolationFilters>({
    violationLabel: '',
    violationTitle: '',
    violationStatus: '',
    dateRange: { start: '', end: '' },
    amountRange: { min: '', max: '' },
  });
  const { auth } = useAuth();
  const csvHeaders = [
    {
      label: 'Violation Label',
      key: 'offenceLabel',
      editable: true,
    },
    {
      label: 'Date',
      key: 'dateCreated',
    },

    {
      label: 'Violation Title',
      key: 'offenceTitle',
    },
    {
      label: 'Violation Status',
      key: 'status',
    },
    {
      label: 'Fine Amount',
      key: 'amount',
    },
  ];
  const columns: Column[] = [
    { header: 'Violation Label', accessorKey: 'offenceLabel', editable: true },
    {
      header: 'Date',
      accessorKey: 'dateCreated',
      cell: ({ value }: { value: string }) =>
        moment(value).format('Do MMMM YYYY'),
    },
    {
      header: 'Time',
      accessorKey: 'dateCreated',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (value: any) => moment(value).format('h:mm a'),
    },
    {
      header: 'Status',
      accessorKey: 'status',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (value: any) => (
        <span
          className={` text-white px-3 py-1 rounded-3xl justify-center flex items-center ${value === 'Active' ? ' bg-green-500' : ' bg-red-500'}`}
        >
          {value}
        </span>
      ),
    },
    {
      header: 'Fine Amount',
      accessorKey: 'amount',
    },
  ];
  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text('Violations Report', 20, 10);
    autoTable(doc, {
      head: [
        [
          'Violations Label',
          'Date',
          'Time',
          'Violation Title',
          'Violation Status',
          'Fine Amount',
        ],
      ],
      body: filteredData.map((item) => [
        item.offenceLabel,
        moment(item.dateCreated).format('YYYY-MM-DD'),
        moment(item.dateCreated).format('hh:mm A'),
        item.offenceTitle,
        item.status,
        item.amount,
      ]),
    });
    doc.save('violations-report.pdf');
  };
  const applyFilters = () => {
    let result = [...initialData];

    if (filters.violationLabel) {
      result = result.filter((item) =>
        item.offenceLabel
          .toLowerCase()
          .includes(filters.violationLabel.toLowerCase()),
      );
    }

    if (filters.violationTitle) {
      result = result.filter((item) =>
        item.offenceTitle
          .toLowerCase()
          .includes(filters.violationTitle.toLowerCase()),
      );
    }
    if (filters.violationStatus) {
      result = result.filter((item) =>
        item.status
          .toLowerCase()
          .includes(filters.violationStatus.toLowerCase()),
      );
    }

    if (filters.dateRange.start && filters.dateRange.end) {
      result = result.filter((item) => {
        const date = new Date(item.dateCreated);
        return (
          date >= new Date(filters.dateRange.start) &&
          date <= new Date(filters.dateRange.end)
        );
      });
    }

    if (filters.amountRange.min || filters.amountRange.max) {
      result = result.filter((item) => {
        const min = filters.amountRange.min
          ? parseFloat(filters.amountRange.min)
          : -Infinity;
        const max = filters.amountRange.max
          ? parseFloat(filters.amountRange.max)
          : Infinity;
        return item.amount >= min && item.amount <= max;
      });
    }

    setFilteredData(result);
    setIsFilterOpen(false);
  };

  const resetFilters = () => {
    setFilters({
      violationLabel: '',
      violationTitle: '',
      violationStatus: '',
      dateRange: { start: '', end: '' },
      amountRange: { min: '', max: '' },
    });
    setFilteredData(initialData);
    setIsFilterOpen(false);
  };
  const onClose = () => setIsFilterOpen(false);
  const handleRowUpdate = (updatedRow: Offence) => {
    console.log(updatedRow);
  };
  return (
    <div className="space-y-4">
      <div className="bg-white rounded-lg shadow p-4">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold text-gray-800">
            Violations List
          </h2>
          {auth === 'admin' && (
            <div className="flex space-x-4">
              <Button
                label="Reports"
                color="black"
                backGroundColor="gray-100"
                hoverBackgroundColor="gray-200"
                hoverColor="black"
                Icon={FileText}
                onClick={() => setIsReportOpen(true)}
              />
              <Button
                label="Filters"
                color="black"
                backGroundColor="gray-100"
                hoverBackgroundColor="gray-200"
                hoverColor="black"
                Icon={Filter}
                onClick={() => setIsFilterOpen(!isFilterOpen)}
              />
            </div>
          )}
        </div>
        {isReportOpen && (
          <DialogLayout
            isOpen={isReportOpen}
            onClose={() => setIsReportOpen(false)}
          >
            <div className="p-8 w-full">
              <div className="flex flex-col space-y-4 items-center justify-center  mt-12">
                <CSVLink
                  data={filteredData}
                  headers={csvHeaders}
                  filename="violations.csv"
                >
                  <Button
                    label="Download as CSV"
                    color="white"
                    backGroundColor="black"
                    hoverBackgroundColor="gray-200"
                    hoverColor="black"
                    Icon={Sheet}
                  />
                </CSVLink>
                <Button
                  label="Download as PDF"
                  color="white"
                  backGroundColor="black"
                  hoverBackgroundColor="gray-200"
                  hoverColor="black"
                  Icon={FileText}
                  onClick={exportPDF}
                />
              </div>
            </div>
          </DialogLayout>
        )}

        {isFilterOpen && (
          <DialogLayout isOpen={isFilterOpen} onClose={onClose}>
            <div className="mt-4 p-4 bg-gray-50 rounded-lg space-y-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Location Search */}
                <div className="mb-4">
                  <label className="block text-lg font-medium text-gray-700 mb-1">
                    Violation Label
                  </label>
                  <input
                    type="text"
                    value={filters.violationLabel}
                    onChange={(e) =>
                      setFilters({ ...filters, violationLabel: e.target.value })
                    }
                    placeholder="Search by offence label..."
                    className="w-full px-3 py-3 text-lg border rounded-md"
                  />
                </div>

                {/* Vehicle Info Search */}
                <div>
                  <label className="block text-lg font-medium text-gray-700 mb-1">
                    Violation Title
                  </label>
                  <input
                    type="text"
                    value={filters.violationTitle}
                    onChange={(e) =>
                      setFilters({ ...filters, violationTitle: e.target.value })
                    }
                    placeholder="Search by violation title..."
                    className="w-full px-3 py-3 text-lg border rounded-md"
                  />
                </div>

                {/* Date Range */}
                <div>
                  <label className="block text-lg font-medium text-gray-700 mb-1">
                    Date Range
                  </label>
                  <div className="flex space-x-2">
                    <input
                      type="date"
                      value={filters.dateRange.start}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          dateRange: {
                            ...filters.dateRange,
                            start: e.target.value,
                          },
                        })
                      }
                      className="flex-1 px-3 py-3 text-lg border rounded-md"
                    />
                    <input
                      type="date"
                      value={filters.dateRange.end}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          dateRange: {
                            ...filters.dateRange,
                            end: e.target.value,
                          },
                        })
                      }
                      className="flex-1 px-3 py-3 text-lg border rounded-md"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-lg font-medium text-gray-700 mb-1">
                    Violation Status
                  </label>
                  <select
                    value={filters.violationStatus}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        violationStatus: e.target.value,
                      })
                    }
                    className="w-full px-3 py-3 text-lg border rounded-md"
                  >
                    <option selected>select an option</option>
                    <option value="ACTIVE">Active</option>
                    <option value="NOTACTIVE">Not active</option>
                  </select>
                </div>
              </div>

              {/* Filter Actions */}
              <div className="flex justify-end space-x-2">
                <Button
                  label="Reset"
                  color="gray-600"
                  backGroundColor="white"
                  hoverBackgroundColor="white"
                  hoverColor="gray-800"
                  onClick={resetFilters}
                />
                <SubmitButton
                  label="Apply Filters"
                  onClick={applyFilters}
                  Icon={Filter}
                />
              </div>
            </div>
          </DialogLayout>
        )}
      </div>
      <div className="container mx-auto px-4">
        <Table
          data={filteredData}
          columns={columns}
          pageSize={pageSize}
          onRowClick={onRowClick}
          goToPage={goToPage}
          totalPages={totalPages}
          onRowUpdate={handleRowUpdate}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};
export default ViolationsTable;
