import React, { useState } from 'react';
import Table, { Column } from 'components/common/table';
import moment from 'moment';
import { Plus } from 'lucide-react';
import { User } from 'pages/Users';
import AddUser from 'pages/Users/AddUser';
import DialogLayout from 'layouts/DialogLayout';

interface UsersTableProps {
  data: User[];
  onRowClick?: (user: User) => void;
  totalPages: number;
  goToPage: (pageNumber: number) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({
  data,
  onRowClick,
  goToPage,
  totalPages,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const columns: Column[] = [
    { header: 'First Name', accessorKey: 'firstName' },

    { header: 'Last Name', accessorKey: 'lastName' },
    {
      header: 'Date',
      accessorKey: 'dateCreated',
      cell: ({ value }: { value: string }) =>
        moment(value).format('Do MMMM YYYY'),
    },

    { header: 'Email', accessorKey: 'email' },

    {
      header: 'Role',
      accessorKey: 'role',
    },
  ];

  return (
    <div className="space-y-4">
      <div className="bg-white rounded-lg shadow p-4">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold text-gray-800">Users List</h2>
          <button
            onClick={() => setIsOpen(true)}
            className="flex items-center px-3 py-2  text-white space-x-2 text-sm bg-black rounded-md hover:text-black hover:bg-gray-200"
          >
            <Plus size={16} />
            <span>Add new user</span>
          </button>
          {isOpen && (
            <DialogLayout width="100%" isOpen onClose={() => setIsOpen(false)}>
              <AddUser />
            </DialogLayout>
          )}
        </div>
      </div>
      <div className="container mx-auto px-4">
        <Table
          data={data}
          columns={columns}
          pageSize={10}
          onRowClick={onRowClick}
          goToPage={goToPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};
export default UsersTable;
