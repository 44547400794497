import UsersTable from 'components/users/UsersTable';
import moment from 'moment';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
export interface User {
  firstName: string;
  lastName: string;
  dateCreated: string;
  email: string;
  role: string;
}
const Users = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const goToPage = (pageNumber: number) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('page', pageNumber.toString());
    setSearchParams(newSearchParams);
  };
  const generateUsers = (count: number): User[] => {
    const firstNames = ['John', 'Jane', 'Alice', 'Bob', 'Chris'];
    const lastNames = ['Doe', 'Smith', 'Johnson', 'Brown', 'Davis'];

    const roles = ['admin', 'analyst'];

    const users: User[] = [];

    for (let i = 0; i < count; i++) {
      const randomFirstName =
        firstNames[Math.floor(Math.random() * firstNames.length)];
      const randomLastName =
        lastNames[Math.floor(Math.random() * lastNames.length)];

      const randomRole = roles[Math.floor(Math.random() * roles.length)];

      // Generate a random email based on the first and last name
      const email = `${randomFirstName.toLowerCase()}.${randomLastName.toLowerCase()}@example.com`;

      // Random date within the last 30 days
      const dateCreated = moment()
        .subtract(Math.floor(Math.random() * 30), 'days')
        .format('YYYY-MM-DD');

      users.push({
        firstName: randomFirstName,
        lastName: randomLastName,
        dateCreated,
        email,
        role: randomRole,
      });
    }

    return users;
  };

  // Example usage
  const usersData = generateUsers(10);
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <UsersTable
        data={usersData}
        onRowClick={(violation) => console.log(violation)}
        totalPages={1}
        goToPage={goToPage}
      />
    </div>
  );
};

export default Users;
