import { Fine } from './types';

export const getBackgroundColor = (status: string | null): string => {
  switch (status) {
    case 'F':
      return 'bg-red-500'; // Red for 'F'
    case 'P':
      return 'bg-yellow-500'; // Green for 'P'
    case 'S':
      return 'bg-green-500'; // Yellow for 'S'
    case null:
    default:
      return 'bg-gray-300'; // Gray for null or undefined
  }
};
export const processData = (data: Fine[] | undefined, filter: string) => {
  return data?.map((item) => {
    const date = new Date(item.dateCaptured);
    let displayDate;

    switch (filter) {
      case 'daily':
        // Show time for daily view
        displayDate = date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
        break;
      case 'weekly':
      case '2weeks':
        // Show date for weekly views
        displayDate = date.toLocaleDateString([], {
          month: 'short',
          day: 'numeric',
        });
        break;
      case 'monthly':
        // Show day and date for monthly view
        displayDate = date.toLocaleDateString([], {
          month: 'short',
          day: 'numeric',
          weekday: 'short',
        });
        break;

      case '3months': {
        // Show week number for 3-month view
        const weekNum = Math.ceil(
          (date.getTime() - new Date(date.getFullYear(), 0, 1).getTime()) /
            (1000 * 60 * 60 * 24 * 7),
        );
        displayDate = `Week ${weekNum}`;
        break;
      }

      case '6months':
        // Show month and week for 6-month view
        displayDate = date.toLocaleDateString([], {
          month: 'short',
          day: 'numeric',
        });
        break;
      case 'year':
        // Show month for yearly view
        displayDate = date.toLocaleDateString([], {
          month: 'long',
        });
        break;
      default:
        displayDate = date.toLocaleDateString();
    }

    return {
      dateCaptured: displayDate,
      amount: Number(item.amount),
    };
  });
};
