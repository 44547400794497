import React from 'react';
import {
  Upload,
  X,
  File,
  CheckCircle,
  AlertCircle,
  Loader2,
} from 'lucide-react';
import { FileUploadUIProps } from 'utils/types';

const formatFileSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const FileUploadUI: React.FC<FileUploadUIProps> = ({
  accept = '*',
  multiple = false,
  files,
  uploadProgress,
  isDragActive,
  isUploading,
  isSuccess,
  isError,
  errorMessage,
  onFileSelect,
  onFileRemove,
  onUpload,
  onDragStateChange,
}) => {
  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onDragStateChange(e.type === 'dragenter' || e.type === 'dragover');
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onDragStateChange(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    onFileSelect(droppedFiles);
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      onFileSelect(selectedFiles);
    }
  };

  return (
    <div className="w-full max-w-md mx-auto space-y-4">
      <div
        className={`relative border-2 border-dashed rounded-lg p-8 text-center ${
          isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
        } transition-colors duration-200 ease-in-out`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <input
          type="file"
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          onChange={handleFileInput}
          accept={accept}
          multiple={multiple}
          disabled={isUploading}
        />

        <div className="flex flex-col items-center space-y-2">
          <Upload className="w-10 h-10 text-gray-400" />
          <p className="text-sm text-gray-600">
            Drag and drop files here or click to select
          </p>
        </div>
      </div>

      {files.length > 0 && (
        <div className="space-y-2">
          {files.map((file, index) => (
            <div
              key={index}
              className="flex items-center justify-between p-2 bg-gray-50 rounded"
            >
              <div className="flex items-center space-x-2">
                <File className="w-4 h-4 text-gray-400" />
                <div className="flex flex-col">
                  <span className="text-sm text-gray-600">{file.name}</span>
                  <span className="text-xs text-gray-400">
                    {formatFileSize(file.size)}
                  </span>
                </div>
              </div>
              <button
                onClick={() => onFileRemove(index)}
                className="p-1 hover:bg-gray-200 rounded-full"
                disabled={isUploading}
              >
                <X className="w-4 h-4 text-gray-500" />
              </button>
            </div>
          ))}

          {isUploading && (
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                style={{ width: `${uploadProgress}%` }}
              />
            </div>
          )}

          <button
            onClick={onUpload}
            disabled={isUploading || files.length === 0}
            className={`w-full py-2 px-4 rounded-lg text-white font-medium ${
              isUploading
                ? 'bg-blue-400 cursor-wait'
                : isSuccess
                  ? 'bg-green-500'
                  : 'bg-blue-500 hover:bg-blue-600'
            }`}
          >
            {isUploading ? (
              <div className="flex items-center justify-center space-x-2">
                <Loader2 className="w-4 h-4 animate-spin" />
                <span>Uploading... {uploadProgress}%</span>
              </div>
            ) : (
              'Upload File'
            )}
          </button>
        </div>
      )}
      {isSuccess && (
        <div className="flex items-center justify-center space-x-2">
          <CheckCircle className="w-4 h-4" />
          <span>Uploaded!</span>
        </div>
      )}
      {isError && errorMessage && (
        <div className="flex items-center space-x-2 text-red-500">
          <AlertCircle className="w-4 h-4" />
          <span className="text-sm">{errorMessage}</span>
        </div>
      )}
    </div>
  );
};
