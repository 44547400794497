import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import React from 'react';

type DialogProps = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  width?: string;
  maxWidth?: string;
};

const DialogLayout = ({
  children,
  isOpen,
  onClose,
  width = '90%',
  maxWidth = '50%',
}: DialogProps) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
          transition={{ duration: 0.3 }}
        >
          <motion.div
            className="bg-white rounded-lg shadow-lg overflow-hidden"
            style={{
              width,
              maxWidth,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            initial={{
              scale: 0.8,
              opacity: 0,
            }}
            animate={{
              scale: 1,
              opacity: 1,
            }}
            exit={{
              scale: 0.8,
              opacity: 0,
            }}
            onClick={(e) => e.stopPropagation()}
            transition={{ duration: 0.3 }}
          >
            <div className="flex justify-end p-4">
              <button
                onClick={onClose}
                className="p-2 hover:bg-gray-100 bg-gray-100 rounded-full transition-colors"
              >
                <X size={16} />
              </button>
            </div>
            <div className="p-4 max-h-[90vh] overflow-y-auto">{children}</div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default DialogLayout;
