/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  ChevronUp,
  ChevronDown,
  MoreHorizontal,
  Check,
  X,
  Edit,
} from 'lucide-react';
import { useSearchParams } from 'react-router-dom';

// Define Column and TableProps types
export interface Column {
  header: string;
  accessorKey: string;
  cell?: (value: any) => React.ReactNode;
  minWidth?: string; // Allows customization of column width
  editable?: boolean; // New property to mark columns as editable
  editComponent?: React.ComponentType<{
    value: any;
    onChange: (value: any) => void;
    onBlur?: () => void;
  }>; // Optional custom edit component
}

interface TableProps<T> {
  data: T[];
  columns: Column[];
  pageSize?: number;
  currentPage?: number;
  title?: string;
  onRowClick?: (row: T) => void;
  onRowUpdate?: (updatedRow: T) => void;
  totalPages: number;
  goToPage: (pageNumber: number) => void;
}

// Default edit input component
const DefaultEditInput = ({
  value,
  onChange,
  onBlur,
}: {
  value: any;
  onChange: (value: any) => void;
  onBlur?: () => void;
}) => (
  <input
    type="text"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    onBlur={onBlur}
    className="w-full px-2 py-1 border rounded"
  />
);

const Table = <T extends object>({
  data,
  columns,
  pageSize = 10,
  currentPage = 1,
  title,
  onRowClick,
  onRowUpdate,
  totalPages,
  goToPage,
}: TableProps<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showAllPages, setShowAllPages] = useState(false);
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [editedRowData, setEditedRowData] = useState<Partial<T> | null>(null);

  // Extract sorting parameters from the URL
  const sortColumn = searchParams.get('sortColumn') || '';
  const sortDirection = searchParams.get('sortDirection') || 'desc';

  const isDateColumn = (columnKey: string) => {
    const column = columns.find((col) => col.accessorKey === columnKey);
    return column?.header.toLowerCase().includes('date');
  };

  // Sorting logic
  const sortData = (
    dataToSort: T[],
    column: string,
    direction: 'asc' | 'desc',
  ) => {
    if (!column) return dataToSort;

    return [...dataToSort].sort((a, b) => {
      const aValue = (a as Record<string, any>)[column];
      const bValue = (b as Record<string, any>)[column];

      if (isDateColumn(column)) {
        const dateA = new Date(aValue);
        const dateB = new Date(bValue);
        return direction === 'asc'
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      }

      if (direction === 'asc') {
        return aValue > bValue ? 1 : -1;
      }
      return aValue < bValue ? 1 : -1;
    });
  };

  const sortedData = useMemo(() => {
    return sortColumn
      ? sortData(data, sortColumn, sortDirection as 'asc' | 'desc')
      : data;
  }, [data, sortColumn, sortDirection]);

  const handleSort = (columnKey: string) => {
    const newDirection =
      sortColumn === columnKey && sortDirection === 'asc' ? 'desc' : 'asc';
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('sortColumn', columnKey);
    newSearchParams.set('sortDirection', newDirection);
    newSearchParams.set('page', '1');
    setSearchParams(newSearchParams);
  };

  // Pagination logic to determine visible pages
  const getVisiblePages = () => {
    if (showAllPages || totalPages <= 7)
      return Array.from({ length: totalPages }, (_, i) => i + 1);

    let pages = [];
    if (currentPage <= 4) {
      pages = [1, 2, 3, 4, 5, null, totalPages];
    } else if (currentPage >= totalPages - 3) {
      pages = [
        1,
        null,
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    } else {
      pages = [
        1,
        null,
        currentPage - 1,
        currentPage,
        currentPage + 1,
        null,
        totalPages,
      ];
    }
    return pages;
  };

  const handleEditStart = (rowIndex: number) => {
    setEditingRow(rowIndex);
    setEditedRowData({ ...data[rowIndex] });
  };

  const handleEditCancel = () => {
    setEditingRow(null);
    setEditedRowData(null);
  };

  const handleEditSave = () => {
    if (editingRow !== null && editedRowData && onRowUpdate) {
      onRowUpdate(editedRowData as T);
      setEditingRow(null);
      setEditedRowData(null);
    }
  };

  const handleFieldChange = (key: string, value: any) => {
    if (editedRowData) {
      setEditedRowData({
        ...editedRowData,
        [key]: value,
      });
    }
  };

  return (
    <div className="w-full bg-white rounded-lg shadow">
      {/* Title */}
      {title && (
        <div className="px-4 sm:px-6 py-4 border-b">
          <h2 className="text-lg font-semibold text-gray-800">{title}</h2>
        </div>
      )}

      {/* Table */}
      <div className="w-full overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {columns.map((column, index) => (
                <th
                  key={index}
                  className="px-4 sm:px-6 py-3 text-left text-sm font-semibold text-gray-600"
                  style={{ minWidth: column.minWidth }}
                >
                  <div className="flex items-center space-x-1">
                    <span>{column.header}</span>
                    {isDateColumn(column.accessorKey) && (
                      <button
                        onClick={() => handleSort(column.accessorKey)}
                        className="p-1 hover:bg-gray-100 rounded"
                      >
                        {sortColumn === column.accessorKey ? (
                          sortDirection === 'asc' ? (
                            <ChevronUp size={16} />
                          ) : (
                            <ChevronDown size={16} />
                          )
                        ) : (
                          <ChevronUp size={16} className="text-gray-400" />
                        )}
                      </button>
                    )}
                  </div>
                </th>
              ))}
              {onRowUpdate && (
                <th className="px-4 sm:px-6 py-3 text-left text-sm font-semibold text-gray-600">
                  Actions
                </th>
              )}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {sortedData.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className={`${onRowClick ? 'cursor-pointer hover:bg-gray-50' : ''} transition-colors}`}
              >
                {columns.map((column, colIndex) => {
                  const cellValue = (row as Record<string, any>)[
                    column.accessorKey
                  ];

                  // Check if row is being edited
                  if (editingRow === rowIndex && column.editable) {
                    const EditComponent =
                      column.editComponent || DefaultEditInput;
                    return (
                      <td
                        key={colIndex}
                        className="px-4 sm:px-6 py-4 text-sm text-gray-600 whitespace-nowrap"
                      >
                        <EditComponent
                          value={
                            editedRowData?.[
                              column.accessorKey as keyof typeof editedRowData
                            ]
                          }
                          onChange={(value) =>
                            handleFieldChange(
                              column.accessorKey as keyof typeof editedRowData,
                              value,
                            )
                          }
                        />
                      </td>
                    );
                  }

                  // Normal cell rendering
                  return (
                    <td
                      key={colIndex}
                      className="px-4 sm:px-6 py-4 text-sm text-gray-600 whitespace-nowrap"
                    >
                      {column.cell ? column.cell(cellValue) : cellValue}
                    </td>
                  );
                })}

                {/* Edit/Save/Cancel actions column */}
                {onRowUpdate && (
                  <td className="px-4 sm:px-6 py-4 text-sm text-gray-600 whitespace-nowrap">
                    {editingRow === rowIndex ? (
                      <div className="flex space-x-2">
                        <button
                          onClick={handleEditSave}
                          className="text-green-600 hover:bg-green-50 p-1 rounded"
                        >
                          <Check size={16} />
                        </button>
                        <button
                          onClick={handleEditCancel}
                          className="text-red-600 hover:bg-red-50 p-1 rounded"
                        >
                          <X size={16} />
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() => handleEditStart(rowIndex)}
                        className="text-blue-600 hover:bg-blue-50 p-1 rounded"
                      >
                        <Edit size={16} />
                      </button>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="px-4 sm:px-6 py-4 border-t">
        <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
          <div className="text-sm text-gray-600">
            Showing {(currentPage - 1) * pageSize + 1} to{' '}
            {Math.min(currentPage * pageSize, sortedData.length)} of{' '}
            {sortedData.length} entries
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => goToPage(1)}
              disabled={currentPage === 1}
              className="p-2 rounded hover:bg-gray-100 disabled:opacity-50"
            >
              <ChevronsLeft size={16} />
            </button>
            <button
              onClick={() => goToPage(currentPage - 1)}
              disabled={currentPage === 1}
              className="p-2 rounded hover:bg-gray-100 disabled:opacity-50"
            >
              <ChevronLeft size={16} />
            </button>
            {getVisiblePages().map((pageNum, index) =>
              pageNum === null ? (
                <button key={index} onClick={() => setShowAllPages(true)}>
                  <MoreHorizontal size={16} />
                </button>
              ) : (
                <button
                  key={pageNum}
                  onClick={() => goToPage(pageNum)}
                  className={`px-3 py-1 rounded ${
                    currentPage === pageNum
                      ? 'bg-black text-white'
                      : 'hover:bg-gray-100'
                  }`}
                >
                  {pageNum}
                </button>
              ),
            )}
            <button
              onClick={() => goToPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="p-2 rounded hover:bg-gray-100 disabled:opacity-50"
            >
              <ChevronRight size={16} />
            </button>
            <button
              onClick={() => goToPage(totalPages)}
              disabled={currentPage === totalPages}
              className="p-2 rounded hover:bg-gray-100 disabled:opacity-50"
            >
              <ChevronsRight size={16} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
