import {
  UseMutationResult,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';
import { axiosInstance } from 'hooks/auth/AxiosInstance';
import { FineFilters } from 'utils/types';

const filterFines = async (data: FineFilters) => {
  const response = await axiosInstance.post('reports/filter', data);
  return response.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useFilterFines = (): UseMutationResult<any, Error, FineFilters> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: filterFines,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fines'] });
    },
    onError: (error: Error) => {
      console.error('Error filtering fines:', error.message);
    },
  });
};

export default useFilterFines;
