// src/components/Login.tsx
import React, { useState } from 'react';
import NtsaLogo from '../../assets/images/ntsa.svg';
import useFineAuth from 'hooks/fines/useFineAuth';
import FineView from 'pages/Fines/FineVew';

interface DataType {
  ticketNo: string;
  password: string;
}

const FineViewAuth: React.FC = () => {
  const { mutateAsync, isPending } = useFineAuth();
  const [data, setData] = useState<DataType>({ ticketNo: '', password: '' });
  const [error, setError] = useState(false);
  const [response, setResponse] = useState();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (e: any) => {
    e.preventDefault();
    const post = {
      ticketNo: data.ticketNo.trim().toUpperCase(),
      password: data.password.trim().toUpperCase(),
    };
    await mutateAsync(post).then((res) => {
      if (res.error !== undefined && res.error) {
        setError(true);
      } else {
        setResponse(res.fine);
      }
    });
  };
  return (
    <>
      {response === null || response === undefined ? (
        <div className="flex h-screen">
          <div className="w-full  flex items-center justify-center p-6">
            <div className="max-w-md w-full space-y-8">
              {error && (
                <p className="text-red-500 text-center">
                  The provided details are incorrect
                </p>
              )}
              <img src={NtsaLogo} alt="ntsa" className="h-fit w-fit" />
              <p className="text-base text-gray-700 leading-relaxed">
                Your ticket number was in the SMS that was sent to you, and your
                password is the year of manufacture of your vehicle and the last
                three digits of your plate number. For example, if your vehicle
                was manufactured in
                <span className="font-semibold text-blue-500"> 2005 </span>
                and your plate number is
                <span className="font-semibold text-blue-500"> KDK123A</span>,
                your password is
                <span className="font-semibold text-blue-500"> 200523A</span>.
              </p>
              <form onSubmit={onSubmit} className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Ticket Number
                  </label>
                  <input
                    id="ticketNo"
                    value={data.ticketNo}
                    name="ticketNo"
                    type="text"
                    onChange={(e) =>
                      setData({ ...data, ticketNo: e.target.value })
                    }
                    required
                    className="w-full mt-1 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                  />
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={data.password}
                    onChange={(e) =>
                      setData({ ...data, password: e.target.value })
                    }
                    required
                    className="w-full mt-1 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                  />
                </div>

                <button
                  type="submit"
                  disabled={isPending}
                  className="w-full py-2 px-4 bg-ntsaGreen text-white rounded-md hover:bg-blue-700 transition-colors duration-300"
                >
                  {isPending ? 'Loading...' : ' View Details'}
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <FineView fine={response} />
      )}
    </>
  );
};

export default FineViewAuth;
