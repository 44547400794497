import {
  Home,
  AlertTriangle,
  DollarSign,
  Users,
  MessageCircleMore,
} from 'lucide-react';

export const SidebarItems = [
  { title: 'Dashboard', icon: Home, path: '/' },
  { title: 'Violations', icon: AlertTriangle, path: '/violations' },
  { title: 'Fines', icon: DollarSign, path: '/fines' },
  { title: 'Sms', icon: MessageCircleMore, path: '/sms' },
  { title: 'Users', icon: Users, path: '/users' },
];
