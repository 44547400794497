import axios from 'axios';

import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { FineImageResponse } from 'utils/types';
const username = process.env.REACT_APP_USERNAME || '';
const password = process.env.REACT_APP_PASSWORD || '';
const encodeCredentials = (user: string, passcode: string) => {
  return btoa(`${user}:${passcode}`);
};
type Options = {
  id: string;
};
const fetchData = async ({ id }: Options): Promise<FineImageResponse> => {
  const credentials = encodeCredentials(username, password);
  try {
    const response = await axios.get(
      `http://192.168.178.39/api/search/one/multi_event/owner/${id}/attachment/${id}_1/field/all_images`,
      {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

const useGetFineImage = ({
  id,
}: Options): UseQueryResult<FineImageResponse, Error> => {
  return useQuery<FineImageResponse, Error>({
    queryKey: ['fines', id],
    queryFn: () => fetchData({ id }),
  });
};

export default useGetFineImage;
