import React from 'react';
import Skeleton from './Skeleton';

const FineDetailsSkeleton: React.FC = () => {
  return (
    <div className="p-4 bg-white shadow-md rounded-lg h-screen">
      {/* Header */}
      <div className="flex items-center mb-4">
        <Skeleton className="h-8 w-8 rounded-full mr-2" />
        <Skeleton className="h-6 w-32" />
      </div>

      {/* Fine Details Card */}
      <div className="p-4 mb-4 border rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <Skeleton className="h-6 w-32" />
          <Skeleton className="h-6 w-20 rounded-full" />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Skeleton className="h-4 w-3/4" />
          <Skeleton className="h-4 w-3/4" />
          <Skeleton className="h-4 w-3/4" />
          <Skeleton className="h-4 w-3/4" />
          <Skeleton className="h-4 w-3/4" />
          <Skeleton className="h-4 w-3/4" />
        </div>
      </div>

      {/* SMS Details */}
      <div className="p-4 mb-4 border rounded-lg">
        <Skeleton className="h-6 w-32 mb-4" />
        <Skeleton className="h-4 w-full" />
      </div>

      {/* Owner Details */}
      <div className="p-4 border rounded-lg">
        <Skeleton className="h-6 w-32 mb-4" />
        <div className="grid grid-cols-2 gap-4">
          <Skeleton className="h-4 w-3/4" />
          <Skeleton className="h-4 w-3/4" />
          <Skeleton className="h-4 w-3/4" />
          <Skeleton className="h-4 w-3/4" />
        </div>
      </div>
    </div>
  );
};

export default FineDetailsSkeleton;
