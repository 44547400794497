import {
  UseMutationResult,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';
import { axiosInstance } from 'hooks/auth/AxiosInstance';

type TrailLog = {
  id: string;
};
const getTrailLogs = async ({ id }: TrailLog) => {
  const post = {
    ticketNumber: id,
  };
  const response = await axiosInstance.post('reports/trailLog', post);
  return response.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useGetTrailLogs = (): UseMutationResult<any, Error, TrailLog> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: getTrailLogs,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fine'] });
    },
    onError: (error: Error) => {
      console.error('Error getting trail logs:', error.message);
    },
  });
};

export default useGetTrailLogs;
