import { SingleFine } from 'utils/types';
import { AxiosResponse } from 'axios';
import { axiosInstance } from 'hooks/auth/AxiosInstance';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
const fetchData = async (id: string): Promise<SingleFine> => {
  try {
    const response: AxiosResponse<SingleFine> = await axiosInstance.get(
      `fine/${id}`,
    );
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error('An uknown error occured');
    }
  }
};

const useGetSingleFine = (id: string): UseQueryResult<SingleFine, Error> => {
  return useQuery<SingleFine, Error>({
    queryKey: ['fine', id],
    queryFn: () => fetchData(id),
  });
};

export default useGetSingleFine;
